.section {
  margin-bottom: 2.857143rem;
}

.titleWrap {
  position: relative;
  width: 100%;
  height: 4.285714rem;
  background-color: #f7f7f7;
  margin-bottom: 1.357143rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.brand {
  width: 0.357143rem;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 0.357143rem;
  -ms-flex: 0 0 0.357143rem;
  flex: 0 0 0.357143rem;
  height: 1.428571rem;
  background: #d6ab56;
  margin-left: 0.5rem;
  margin-right: 0.571429rem;
}

.title {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 1.428571rem;
  color: #212223;
  margin-right: 2.142857rem;
  font-weight: 500;
  text-decoration: none;
}

.title:hover {
  text-decoration: none;
}

.subNav {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  overflow: hidden;
}

.subNavItem {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 1.142857rem;
  color: #84848a;
}

.subNavItem a {
  font-size: 1.142857rem;
  color: #84848a;
  text-decoration: none;
}

.subNavItem a:hover {
  color: #d6ab56;
}

.subNavSeparate {
  margin: 0 0.571429rem;
}

.more {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin-left: 0.714286rem;
  margin-right: 0.714286rem;
}

.more a {
  font-size: 1rem;
  color: #84848a;
  text-decoration: none;
}

.more a:hover {
  color: #d6ab56;
}

.contentWrap {
  margin-bottom: 2.857143rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.contentItem {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 13.857143rem;
  -ms-flex: 0 0 13.857143rem;
  flex: 0 0 13.857143rem;
  margin-right: 1.428571rem;
}

.contentItem:last-child {
  margin-right: 0;
}

.contentItemLink {
  color: #212223;
  text-decoration: none;
}

.contentItemLink:hover {
  text-decoration: none;
}

.contentItemLink:hover .contentTitle {
  color: #d6ab56;
}

.contentItemLink:hover .contentThumbnailImg {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}

.contentThumbnail {
  width: 13.857143rem;
  height: 7.857143rem;
  margin-bottom: 0.714286rem;
  overflow: hidden;
}

.contentThumbnail img {
  max-width: 100%;
  display: block;
}

.contentThumbnail .contentThumbnailImg {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.contentThumbnail .noThumbnail {
  background-color: #e2e2e2;
  height: 7.857143rem;
  background-image: url(../../../images/no_thumbnail_logo.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 9.285714rem;
}

.contentTitle {
  font-size: 1.142857rem;
  line-height: 1.571429rem;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  height: 3.142857rem;
  margin-bottom: 0.714286rem;
}

.contentInfo {
  font-size: 0.857143rem;
  color: #84848a;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.contentInfoNum {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.contentInfoNumIcon {
  width: 0.928571rem;
  height: 0.714286rem;
  margin-right: 0.214286rem;
  background-image: url(../../../images/i_eye.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 0.928571rem 0.714286rem;
}

.contentInfoTime {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: right;
  margin-left: 0.357143rem;
}