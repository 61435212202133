.nav {
  background-color: #d6ab56;
  height: 2.857143rem;
  margin-bottom: 1.428571rem;
}

.navContainer {
  margin: 0 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.large {
  width: 100rem;
}

.normal {
  width: 88.571429rem;
}

.navItem {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.navItem:hover {
  background-color: rgba(0, 0, 0, .1);
}

.navItem.active {
  background-color: rgba(0, 0, 0, .15);
}

.navItemLink {
  display: block;
  text-decoration: none;
}

.navItemLink:hover {
  text-decoration: none;
}

.navItemContent {
  padding-left: 2.142857rem;
  padding-right: 2.142857rem;
  line-height: 2.857143rem;
  text-align: center;
  color: #ffffff;
  font-size: 1.142857rem;
  font-weight: 500;
  text-decoration: none;
}

.navItemScroll {
  cursor: pointer;
}