.footer {
  width: 100%;
  border-top: 1px solid #ccc;
  background: none;
}

:global(.hairlines) .footer {
  border-top: 0.5px solid #ccc;
}

.foot_link {
  position: relative;
  height: 1.428571rem;
  padding-top: 1.071429rem;
  border-top: 1px solid #fff;
  overflow: hidden;
  color: #000;
  line-height: 1.428571rem;
  font-size: 0.857143rem;
}

:global(.hairlines) .foot_link {
  border-top: 0.5px solid #fff;
}

.list {
  text-align: center;
}

.link {
  color: #000 !important;
  font-size: 1rem;
  font-weight: 400;
  text-decoration: none;
  padding: 0 0.714286rem;
}

.link:not(:last-child) {
  border-right: 1px solid #e8e8e8 !important;
}

.link:hover {
  color: #f54343 !important;
  text-decoration: underline;
}

.link:first-child {
  padding-left: 0;
}

.link:last-child {
  border: 0;
}

.copyright {
  color: #666;
  height: 2rem;
  text-align: center;
  overflow: hidden;
  line-height: 2rem;
  margin-top: 0.214286rem;
}

.copyright span {
  padding-right: 0.857143rem;
  font-size: 1rem;
}