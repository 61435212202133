.wrap {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 1.785714rem 0;
  height: 7.142857rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.live {
  margin-right: 2.857143rem;
}

.liveLink {
  text-decoration: none;
}

.liveLink:hover {
  text-decoration: none;
}

.liveLink:hover .liveTitle {
  color: #d6ab56;
}

.liveWrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 3.571429rem;
}

.liveIcon {
  width: 1.785714rem;
  margin-right: 0.714286rem;
}

.liveIcon img {
  width: 100%;
}

.liveTitle {
  color: #212223;
  font-size: 1.714286rem;
  line-height: 2.357143rem;
  font-weight: 500;
}

.subLive {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 17.857143rem;
  -ms-flex: 0 0 17.857143rem;
  flex: 0 0 17.857143rem;
  width: 17.857143rem;
}

.subLiveLink {
  text-decoration: none;
}

.subLiveLink:hover {
  color: #d6ab56;
  text-decoration: none;
}

.subLiveLink:hover .subLiveTitle {
  color: #d6ab56;
}

.subLiveLink:hover .subLiveDesc {
  color: #d6ab56;
}

.subLiveLink:hover .subLiveCircle {
  border: 0.142857rem solid #d6ab56;
}

.subLiveWrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 3.571429rem;
}

.subLiveLeft {
  width: 3.571429rem;
  height: 3.571429rem;
  position: relative;
  margin-right: 0.714286rem;
}

.subLiveCircle {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  width: 3.571429rem;
  height: 3.571429rem;
  border-radius: 50%;
  border: 0.142857rem solid #f7f7f7;
  background-color: #f7f7f7;
  z-index: -1;
}

.subLiveLeftBg {
  width: 3.571429rem;
  height: 3.571429rem;
}

.subLiveLeftFg {
  width: 1.285714rem;
  height: 1.285714rem;
  position: absolute;
  bottom: 0;
  right: 0;
}

.subLiveRight {
  height: 3.571429rem;
}

.subLiveTitle {
  font-size: 1.428571rem;
  line-height: 2rem;
  height: 2rem;
  color: #212223;
  font-weight: 500;
}

.subLiveDesc {
  font-size: 1rem;
  line-height: 1.428571rem;
  height: 1.428571rem;
  color: #84848a;
}