.footer {
  width: 100%;
  margin: 0 auto;
  border-top: 0.142857rem solid #d6ab56;
  padding: 2.142857rem 0 6.928571rem;
}

.colWrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  margin-bottom: 1.214286rem;
}

.col {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin-left: 5rem;
  margin-right: 5rem;
}

.col li {
  font-size: 1.142857rem;
  margin-bottom: 1.142857rem;
  color: #84848a;
}

.col li a {
  color: #84848a;
  font-size: 1.142857rem;
  text-decoration: none;
}

.col li a:hover {
  text-decoration: none;
  color: #d6ab56;
}

.col li.colTitle {
  color: #212223;
  font-weight: 500;
}

.footerBottom {
  width: 71.428571rem;
  margin: 0 auto;
  height: 5.714286rem;
  background: none;
}

.footerCopyright {
  border-top: 0 !important;
}