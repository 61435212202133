.slideContaniner {
  width: 55.714286rem;
  height: 31.428571rem;
  position: relative;
}

[ifeng_ui_pc_sliders-dots] {
  width: 19.285714rem;
  height: 31.428571rem !important;
  right: 0 !important;
  left: 100% !important;
  bottom: 0 !important;
  top: 0;
}

.dot {
  /* box-sizing: border-box; */
  display: block !important;
  width: 19.285714rem !important;
  height: 6.285714rem !important;
  background-color: #f7f7f7 !important;
  position: relative;
}

.dot:hover {
  opacity: 1 !important;
}

.current {
  /* box-sizing: border-box; */
  display: block !important;
  width: 19.285714rem !important;
  height: 6.285714rem !important;
  position: relative;
  background-color: #d6ab56;
}

.current:hover {
  opacity: 1 !important;
}

.slideItem a {
  display: block;
  height: 100%;
}

.slideItem a img {
  display: block;
  width: 55.714286rem;
  height: 31.428571rem;
}

.innerText {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 1.571429rem 1.428571rem;
  width: 19.285714rem;
  height: 6.285714rem;
  line-height: 1.714286rem;
  text-align: left;
  left: 0;
}

.innerText a {
  display: -webkit-box;
  width: 16.428571rem;
  height: 3.142857rem;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  color: #212223;
  font-size: 1.142857rem;
}

.innerText a:hover {
  text-decoration: none;
}

.current .innerText a {
  color: #fff;
}