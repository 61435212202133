.main {
  width: 100rem;
  margin: 0 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.leftBox {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 75rem;
  -ms-flex: 0 0 75rem;
  flex: 0 0 75rem;
  margin-right: 3.571429rem;
}

.rightBox {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 21.428571rem;
  -ms-flex: 0 0 21.428571rem;
  flex: 0 0 21.428571rem;
}

.headerWrap {
  width: 100rem;
  margin: 0 auto;
}

.footerWrap {
  width: 100rem;
  margin: 0 auto;
  margin-top: 3.571429rem;
}