.main_nav {
  width: 100%;
  height: 1.857143rem;
  margin: 0 auto;
  position: relative;
  z-index: 999;
  background: #fff;
}

.user {
  float: right;
  text-align: right;
  height: 2.714286rem;
  padding-top: 0.5rem;
}

.box {
  width: 100%;
  height: 6.428571rem;
  float: right;
  text-align: center;
  line-height: 6.428571rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  padding: 0;
  margin: 0;
}

.ad {
  width: 100%;
  height: 9rem;
  margin: 0 auto;
}

.main {
  clear: both;
  display: table;
  width: 100%;
  margin-bottom: 0.714286rem;
}

.searchWrap {
  display: table-cell;
  vertical-align: middle;
}

.searchWrap > div {
  width: auto;
}

.searchWrap > div > div {
  width: auto;
}

.searchWrap > div > div > div {
  width: auto;
}

.searchDirction {
  float: right;
}

.logoWrap {
  display: table-cell;
  vertical-align: middle;
}

.logoWrap img {
  display: block;
}