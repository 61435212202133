.titleWrap {
  height: 4.285714rem;
  background: #f7f7f7;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 1.428571rem;
}

.brand {
  width: 0.357143rem;
  height: 1.428571rem;
  background: #d6ab56;
  margin-left: 0.5rem;
  margin-right: 0.571429rem;
}

.title {
  font-size: 1.428571rem;
  color: #212223;
  font-weight: 500;
}

.contentItem {
  margin-bottom: 2.142857rem;
}

.contentItemLink {
  color: #212223;
  text-decoration: none;
}

.contentItemLink:hover {
  text-decoration: none;
}

.contentItemLink:hover .contentTitle {
  color: #d6ab56;
}

.contentItemLink:hover .contentThumbnailImg {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}

.contentThumbnail {
  width: 21.428571rem;
  height: 12.071429rem;
  margin-bottom: 0.714286rem;
  overflow: hidden;
}

.contentThumbnail img {
  max-width: 100%;
  display: block;
}

.contentThumbnail .contentThumbnailImg {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.contentThumbnail .noThumbnail {
  background-color: #e2e2e2;
  height: 12.071429rem;
  background-image: url(../../images/no_thumbnail_logo.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 9.285714rem;
}

.contentTitle {
  font-size: 1.142857rem;
  line-height: 1.571429rem;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}